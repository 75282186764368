import React from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import Multiline from '../Multiline'
import FormController from './FormController'

function FormikWrapper() {

  const initialValues = {
    multi: ''
  }

  const choices = [
    { key: 'Yes', value: 'yes' },
    { key: 'No', value: 'no' }
  ]

  const validationSchema = Yup.object({
    multi: Yup.string().optional()
  })

  const onSubmit = values => console.log('Form data', values)
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {formik => (
        <Form>
          {/* <Multiline label='multi' name='multi' placeholder='Enter your answer here. We are all ears.'/> */}
          <FormController
            control="singlelinename"
            label="Click your choice"
            placeholder="Enter your name"
            name="radioChoice"
            options={choices}
          />
        </Form>
      )}
    </Formik>
  )
}
export default FormikWrapper
