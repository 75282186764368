import React from 'react'
import Layout from '../components/Layout/layout'
import Questionnaire from '../components/Form/Questionnaire'

const IndexPage = () => {
  return (
    <Layout>
      <Questionnaire />
    </Layout>
  )
}

export default IndexPage
